<template>
  <div class="primary">
    <v-container class="py-3">
      <div class="text-center my-3">
        <h3 class="white--text">Redes Sociales</h3>
        <div class="mx-auto" style="max-width: 400px">
          <v-divider
            class="py-1 lighter lighten-1"
            style="opacity: 0.8 !important"
          ></v-divider>
        </div>
      </div>
      <div class="text-center py-3">
        <v-btn
          icon
          dark
          href="https://www.facebook.com/discursaconsultores"
          target="_blank"
        >
          <v-icon size="56" dark>mdi-facebook</v-icon>
        </v-btn>
        <v-btn
          icon
          dark
          href="https://twitter.com/discursa3"
          target="_blank"
          class="ml-10"
        >
          <v-icon size="56" dark>mdi-twitter</v-icon>
        </v-btn>
      </div>
      <div class="my-4 white--text text-center">
        <h3>¡Síguenos en nuestras redes!</h3>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Redes",
  data() {
    return {};
  },
};
</script>
