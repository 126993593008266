import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import "firebase/auth";

const config = {
  apiKey: process.env.VUE_APP_FB_KEY,
  authDomain: "discursa-app.firebaseapp.com",
  databaseURL: "https://discursa-app.firebaseio.com",
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: "discursa-app.appspot.com",
  messagingSenderId: "512946880710",
  appId: "1:512946880710:web:b9130198c41b26637f57c7",
  measurementId: "G-DWPX7JJVPW",
};

firebase.initializeApp(config);

const auth = firebase.auth();
var db = firebase.firestore();
var storageRef = firebase.storage().ref();
const analytics = firebase.analytics();

export { db, storageRef, analytics, auth };
