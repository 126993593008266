<template>
  <div>
    <div class="py-6"></div>
    <v-container>
      <div style="max-width: 70px">
        <v-divider
          class="black"
          style="padding: 7px 0; opacity: 1 !important"
        ></v-divider>
      </div>
      <h2
        class="font-weight-bold mb-5 d-flex flex-row"
        :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
      >
        <v-img
          src="@/assets/mini_logo_blue.png"
          max-width="90px"
          contain
        ></v-img>
        <div class="pt-10">Contacto</div>
      </h2>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-form
            @submit.prevent="sendContact(name, email, phone, msg)"
            enctype="multipart/form-data"
            ref="contact"
          >
            <v-col cols="12">
              <v-text-field
                prepend-inner-icon="mdi-account"
                dense
                label="Nombre"
                name="nombre"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n3">
              <v-text-field
                prepend-inner-icon="mdi-email"
                dense
                label="Email"
                name="email"
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n3">
              <v-text-field
                prepend-inner-icon="mdi-phone"
                dense
                label="Teléfono"
                name="tel"
                v-model="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n3">
              <v-textarea
                outlined
                auto-grow
                rows="3"
                dense
                label="Déjanos tu mensaje"
                name="mensaje"
                v-model="msg"
              ></v-textarea
            ></v-col>
            <v-col cols="12" class="mt-n5">
              <v-btn type="submit" :disabled="disable" class="primary"
                >Enviar <v-icon small right>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <v-col cols="12">
            <p class="mt-n3">
              En DISCURSA sabemos que hay circunstancias en las que un discurso
              adquiere una importancia especial. Por eso nuestras tarifas
              dependen de varios factores, como la audiencia a quien va
              dirigido, el alcance en medios, la complejidad del tema, la
              duración del mensaje y la urgencia para tenerlo listo.
            </p>
            <p>
              Contáctanos para agendar una reunión y conocer tus necesidades.
            </p>
            <p>
              En caso de que requieras otros productos de comunicación y
              contenidos, en DISCURSA podemos hacerte un “traje a la medida”.
            </p>
            <!--   <strong href="mailto:contacto@discursa.com">
              <v-icon left>mdi-email</v-icon> info@discursa.com</strong
            >
            <strong>
              <v-icon left>mdi-whatsapp</v-icon>
              <a
                style="text-decoration: none; color: black"
                href="tel:2223436102"
                >2223436102</a
              >
            </strong> -->
          </v-col>
        </v-col>
      </v-row>
      <v-snackbar :timeout="2000" v-model="snackbar" color="blue" bottom>
        {{ feedback }}
        <template v-slot:action="{ attrs }">
          <v-btn icon light text v-bind="attrs" @click="snackbar = false"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <div class="py-12"></div>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "Contacto",
    data() {
      return {
        snackbar: false,
        name: "",
        email: "",
        phone: "",
        msg: "",
        feedback: "",
        disable: false,
      };
    },
    methods: {
      /* async sendContact(name, email, phone, msg) {
        this.feedback = "";
        if ((name, email, phone, msg)) {
          console.log(name, email, phone, msg);
          try {
            axios({
              method: "POST",
              url: "https://formspree.io/f/xdoylnov",
              data: {
                name: name,
                email: email,
                phone: phone,
                message: msg,
              },
            });
            this.snackbar = true;
            this.feedback = "¡Mensaje Enviado!";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.msg = "";
          } catch (error) {
            console.error(error);
            alert("error " + error);
            this.feedback = "Error, intenta más tarde";
            this.snackbar = true;
          }
        } else {
          this.feedback = "Completa la información";
          this.snackbar = true;
        }
      }, */
      async sendContact(name, email, phone, msg) {
        this.feedback = "";
        if ((name, email, phone, msg)) {
          try {
            axios({
              method: "POST",
              url: "https://submit-form.com/HtcwzfrN",
              data: {
                name: name,
                email: email,
                phone: phone,
                message: msg,
              },
            });
            this.snackbar = true;
            this.feedback = "¡Mensaje Enviado!";
            this.name = "";
            this.email = "";
            this.phone = "";
            this.msg = "";
          } catch (error) {
            console.error(error);
            alert("error " + error);
            this.feedback = "Error, intenta más tarde";
            this.snackbar = true;
          }
        } else {
          this.feedback = "Completa la información";
          this.snackbar = true;
        }
      },
    },
  };
</script>
