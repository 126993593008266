import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { auth } from "@/plugins/firebase.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/inicio",
    name: "Home",
    component: Home,
  },
  {
    path: "/mensaje",
    name: "Mensaje",
    component: () => import("../components/secondary/Mensaje.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/admin/",
    name: "Admin",
    meta: { requiresAuth: true },

    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/miembro/:id",
    name: "Member",
    component: () => import("../views/Member.vue"),
  },
  {
    path: "/blog/:name",
    name: "Post",
    component: () => import("../views/Post.vue"),
  },
  {
    path: "*",
    redirect: "/inicio",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const user = auth.currentUser;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (user) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
