import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/assets/variables.scss"],
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#6c9ccc",
        secondary: "#1cc8f5",
        accent: "#ee4ea5",
        lighter: "#999999",
        error: "#FF5252",
        info: "#93D5BC",
        success: "#8FDA8A",
        warning: "#FFC107",
      },
    },
  },
});
