<template>
  <div>
    <section id="hero">
      <Landing />
    </section>
    <section class="mt-8" id="discurso">
      <Discurso />
    </section>
    <section id="about">
      <About />
    </section>
    <section id="servicios">
      <Servicios />
    </section>
    <section id="metodo">
      <Metodo />
    </section>
    <!--    <section id="tarifas">
      <Tarifas />
    </section> -->
    <section id="contacto">
      <Contacto />
    </section>
    <section id="members">
      <Members />
    </section>
    <section id="blog">
      <Blog />
    </section>
    <section id="redes">
      <Redes />
    </section>
  </div>
</template>

<script>
import Landing from "@/components/main/Landing.vue";
import Discurso from "@/components/main/Discurso.vue";
import About from "@/components/main/About.vue";
import Servicios from "@/components/main/Servicios.vue";
import Metodo from "@/components/main/Metodo.vue";
import Contacto from "@/components/main/Contacto.vue";
import Members from "@/components/main/Members.vue";
import Blog from "@/components/main/Blog.vue";
import Redes from "@/components/main/Redes.vue";
//import Tarifas from "@/components/main/Tarifas.vue";

export default {
  name: "Home",
  components: {
    Landing,
    Discurso,
    About,
    Servicios,
    Metodo,
    Contacto,
    Members,
    Blog,
    Redes,
    // Tarifas,
  },
  data() {
    return {
      dialog: false,
      color: "#f9f9f9",
      hover: false,
      landing: "",
    };
  },
  mounted() {},
};
</script>

<style>
.mn20 {
  margin-top: -350px !important;
}
</style>
