<template>
  <div>
    <div class="py-12"></div>
    <v-container fluid class="px-4 px-md-15">
      <h2
        class="font-weight-bold mb-5"
        :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
      >
        <div class="mb-5" style="max-width: 40px">
          <v-divider style="opacity: 0.7" class="py-1 black"></v-divider>
        </div>
        Método de Trabajo
      </h2>
      <div class="mt-8" style="max-width: 800px; letter-spacing: 0.07em; font-size: 22px">
        <p>
          Los discursos memorables comparten características similares. Todos cuentan una
          historia, defienden ciertas ideas y utilizan diversos recursos retóricos para
          sustentarlas. Todos emplean frases atractivas para anclar su visión a la memoria
          del auditorio y terminan con un llamado a la acción. Estos son los 5 pasos para
          lograrlo:
        </p>
      </div>
    </v-container>
    <v-container fill-height class="pt-15" fluid>
      <v-row>
        <v-col class="p-0" cols="6" md="4" v-for="(s, index) in steps" :key="s.key">
          <v-hover v-slot="{ hover }">
            <v-card
              tile
              :height="$vuetify.breakpoint.smAndDown ? 340 : 300"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                :src="s.image"
                :gradient="
                  hover
                    ? 'to right,  rgba(74,138,194,.8), rgba(74,138,194,.8)'
                    : 'to right, rgba(0,0,0,.5),rgba(0,0,0,.8)'
                "
                class="align-center justify-center text-center"
                width="100%"
                height="100%"
              >
                <div
                  class="align-self-center pa-4"
                  :class="{ 'show-btns': hover }"
                  color="transparent"
                >
                  <span
                    v-if="index != 5"
                    :class="[hover ? 'd-none' : 'd-block']"
                    style="color: white; font-size: 3rem"
                    >{{ index + 1 }}</span
                  >
                  <span
                    class="step"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'texth5' : 'texth4',
                      hover ? 'd-none' : 'd-block',
                    ]"
                    style="color: white"
                  >
                    {{ s.title }}
                  </span>
                  <span
                    class="step"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'texth4' : 'texth3',
                      hover ? 'd-block' : 'd-none',
                    ]"
                    style="color: white; transition: opacity 1s ease-out"
                  >
                    <div class="py-12 px-3">
                      <p
                        :class="[$vuetify.breakpoint.smAndDown ? 'textd3' : 'textd2']"
                        style=" font-weight-bold; line-height: 20px; letter-spacing: .15em"
                        v-for="t in s.text"
                        :key="t.key"
                      >
                        {{ t }}
                      </p>
                    </div>
                  </span>
                </div>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
export default {
  name: "Servicios",
  data() {
    return {
      steps: [],
    };
  },
  mounted() {
    this.getSteps();
  },
  methods: {
    async getSteps() {
      try {
        const metodo = await db.collection("site").doc("metodo").get();

        this.steps = metodo.data().pasos;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
