<template>
  <div>
    <v-container fluid>
      <div style="max-width: 70px">
        <v-divider
          class="primary"
          style="padding: 7px 0; opacity: 0.8 !important"
        ></v-divider>
      </div>
      <h2
        class="font-weight-bold mb-5"
        :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
      >
        Equipo de Escritores
      </h2>
    </v-container>
    <v-container class="py-8" fluid>
      <div class="py-12"></div>
      <v-row no-gutters>
        <v-col
          class="pa-0 ma-0"
          cols="12"
          sm="6"
          md="3"
          v-for="m in members"
          :key="m.key"
        >
          <v-hover v-slot="{ hover }">
            <v-card  tile class="mx-0" color="transparent" >
              <v-img height="400"  :src="m.image">
                <v-expand-transition>
                  <div
                    v-if="hover"
                    class="
                      d-flex
                      flex-column
                      justify-center
                      transition-fast-in-fast-out
                      v-card--reveal
                      white--text
                    "
                    style="height: 100%; font-size: 24px; color: white ;"
                  >
                    <div>
                      {{ m.nombre }}
                    </div>
                    <div>
                      <v-btn
                        :to="{ name: 'Member', params: { id: m.url } }"
                        small
                        >Ver más</v-btn
                      >
                    </div>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Members",
  data() {
    return {
      members: [
        {
          nombre: "Raul Hermosillo",
          image:
            "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/rhc3.jpg?alt=media&token=d0b275be-1cb8-4c67-a398-2bac39e85fe5",
          short: "",
          url: "raul-hermosillo",
        },
        {
          nombre: "Vicente Barrera",
          image:
            "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/discursaMember.jpg?alt=media&token=2fc264fd-404f-4ece-a97f-b8f5c0281099",
          short: "",
          url: "vicente-barrera",
        },
        {
          nombre: "Lizbeth Higareda",
          image:
            "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/lizh.png?alt=media&token=f7170cec-39fb-4c27-9332-0862defb91ec",
          url: "lizbeth-higareda",
        },
        {
          nombre: "Jorge Hernández",
          image:
            "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/jhdz.jpg?alt=media&token=3f65050f-3ce8-48b1-95cf-df203a16ce68",
          short: "",
          url: "jorge-hernandez",
        },
      ],
    };
  },
};
</script>
