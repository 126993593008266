import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/plugins/firebase.js";
import router from "@/router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    memberText: [
      {
        member: "raul-hermosillo",
        name: "Raul Hermosillo",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/rhc3.jpg?alt=media&token=d0b275be-1cb8-4c67-a398-2bac39e85fe5",
        text: [
          "Es Licenciado en Sociología de la Universidad Autónoma Metropolitana, Maestro en Ciencias Económicas por la Universidad Nacional Autónoma de México y Maestro en Ciencias Políticas por la Universidad de Essex, Inglaterra. ",
          "Ha sido profesor en el Departamento de Economía en la Universidad de las Américas, Puebla, en el Instituto de Administración Pública del Estado de México (IAPEM) y Consultor Académico de la Dirección Ejecutiva del Servicio Profesional Electoral del Instituto Federal Electoral.",
          "Ha sido Director General Adjunto de Discursos de Presidencia de la República, Director General Adjunto de Seguimiento de Imagen Presidencial y Coordinador General de Mensaje Gubernamental en el Gobierno de Puebla.",
          "	Tiene amplia experiencia en elaboración de discurso y el diseño de mensaje gubernamental y comunicación política. Habilidad en el manejo de crisis de reputación e imagen. Políticas públicas y programas de impacto social y medioambiental.",
        ],
      },
      {
        member: "vicente-barrera",
        name: "Vicente Barrera",
        picture: "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/discursaMember.jpg?alt=media&token=2fc264fd-404f-4ece-a97f-b8f5c0281099",
        text: [
          "Es Licenciado en Ciencias de la Comunicación por la Universidad Nacional Autónoma de México.",
          "Ha sido editor de publicaciones en la Secretaría de Educación Pública y Director de Información y Difusión en programas sociales federales.",
          "Se desempeñó como Director General Adjunto de Discursos en la Presidencia de la República durante cuatro administraciones y asesor en la elaboración de mensajes en la Coordinación General de Asesores de la Presidencia del Tribunal Electoral del Poder Judicial de la Federación (TEPJF).",
          "Cuenta con una larga experiencia en la elaboración de discursos y productos de comunicación política. Amplio conocimiento en políticas públicas y en programas vinculados con desarrollo social, salud, vivienda, educación, equidad de género, seguridad y justicia electoral.",
        ],
      },
      {
        member: "lizbeth-higareda",
        name: "Lizbeth Higareda",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/lizb.png?alt=media&token=05a7a63a-5607-4074-af06-2b83bb484b9d",
        text: [
          "Estudió la carrera Relaciones Internacionales en la Universidad Nacional Autónoma de México. Tiene diplomados en “Género y Política Públicas” por la Facultad Latinoamericana de Ciencias Sociales, en “Relaciones de Género” por el Programa Universitario de Estudios de Género de la UNAM y en “Violencia y Derechos Humanos” por el Instituto de Investigaciones Jurídicas de la misma Universidad. ",
          "Se desempeñó como Asesora Parlamentaria en la Cámara de Diputados del H. Congreso de la Unión durante tres Legislaturas y fue consultora de comunicación y diseño de mensaje de la Mesa Directiva de dicho órgano legislativo. En la Administración Pública Federal fue Directora de Discurso en la Coordinación de Asesores de la Presidencia de la República. ",
          "Posee una gran experiencia en la elaboración de discursos y creación de contenidos en temas relacionados con igualdad, equidad de género, derechos humanos, violencia y no discriminación, así como derechos de la infancia y adolescencia.  ",
        ],
      },
      {
        member: "jorge-hernandez",
        name: "Jorge Hernández",
        picture:
          "https://firebasestorage.googleapis.com/v0/b/discursa-app.appspot.com/o/jhdz.jpg?alt=media&token=3f65050f-3ce8-48b1-95cf-df203a16ce68",
        text: [
          "Es Licenciado en Economía por el ITAM y cuenta con una Maestría en Comunicación Institucional por el Instituto Ortega y Gasset de Madrid.",
          "Con más de 15 años de experiencia profesional en los sectores público y privado, se ha especializado en el desarrollo de estrategias de comunicación y la creación de contenidos de impacto.",
          "Al mezclar herramientas de creatividad, análisis profundo y pensamiento estratégico ha podido asesorar a diferentes líderes del más alto nivel, empresas y organismos a contar su historia, a transmitir eficazmente su mensaje y a influir positivamente en la conversación pública.",
        ],
      },
    ],
    usuario: {},
    feedback: "",
  },
  mutations: {
    setUser(state, payload) {
      state.usuario = payload;
    },
    setFeedback(state, payload) {
      state.feedback = payload;
    },
  },
  actions: {
    async login({ commit }, usuario) {
      this.state.feedback = "";
      try {
        const user = await auth.signInWithEmailAndPassword(
          usuario.email,
          usuario.password
        );
        router.push("/admin");
        commit("setUser", user);
      } catch (error) {
        this.state.feedback = "Revisa los campos";
      }
    },
    async logout({ commit }) {
      auth.signOut();
      commit("setUser", null);
      router.push({ name: "Login" });
    },
    detectUser({ commit }, user) {
      const usuario = {
        email: user.email,
      };
      commit("setUser", usuario);
    },
  },

  modules: {},
});
