var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"px-4 px-md-15",attrs:{"fluid":""}},[_c('h2',{staticClass:"font-weight-bold mb-5",class:[_vm.$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']},[_c('div',{staticClass:"mb-5",staticStyle:{"max-width":"40px"}},[_c('v-divider',{staticClass:"py-1 black",staticStyle:{"opacity":"0.7"}})],1),_vm._v(" Método de Trabajo ")]),_c('div',{staticClass:"mt-8",staticStyle:{"max-width":"800px","letter-spacing":"0.07em","font-size":"22px"}},[_c('p',[_vm._v(" Los discursos memorables comparten características similares. Todos cuentan una historia, defienden ciertas ideas y utilizan diversos recursos retóricos para sustentarlas. Todos emplean frases atractivas para anclar su visión a la memoria del auditorio y terminan con un llamado a la acción. Estos son los 5 pasos para lograrlo: ")])])]),_c('v-container',{staticClass:"pt-15",attrs:{"fill-height":"","fluid":""}},[_c('v-row',_vm._l((_vm.steps),function(s,index){return _c('v-col',{key:s.key,staticClass:"p-0",attrs:{"cols":"6","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"tile":"","height":_vm.$vuetify.breakpoint.smAndDown ? 340 : 300,"elevation":hover ? 12 : 2}},[_c('v-img',{staticClass:"align-center justify-center text-center",attrs:{"src":s.image,"gradient":hover
                  ? 'to right,  rgba(74,138,194,.8), rgba(74,138,194,.8)'
                  : 'to right, rgba(0,0,0,.5),rgba(0,0,0,.8)',"width":"100%","height":"100%"}},[_c('div',{staticClass:"align-self-center pa-4",class:{ 'show-btns': hover },attrs:{"color":"transparent"}},[(index != 5)?_c('span',{class:[hover ? 'd-none' : 'd-block'],staticStyle:{"color":"white","font-size":"3rem"}},[_vm._v(_vm._s(index + 1))]):_vm._e(),_c('span',{staticClass:"step",class:[
                    _vm.$vuetify.breakpoint.smAndDown ? 'texth5' : 'texth4',
                    hover ? 'd-none' : 'd-block' ],staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(s.title)+" ")]),_c('span',{staticClass:"step",class:[
                    _vm.$vuetify.breakpoint.smAndDown ? 'texth4' : 'texth3',
                    hover ? 'd-block' : 'd-none' ],staticStyle:{"color":"white","transition":"opacity 1s ease-out"}},[_c('div',{staticClass:"py-12 px-3"},_vm._l((s.text),function(t){return _c('p',{key:t.key,class:[_vm.$vuetify.breakpoint.smAndDown ? 'textd3' : 'textd2'],staticStyle:{"line-height":"20px","letter-spacing":".15em"}},[_vm._v(" "+_vm._s(t)+" ")])}),0)])])])],1)]}}],null,true)})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }