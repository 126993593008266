<template>
  <div style="position: relative; width: 100vw; height: 90vh">
    <v-carousel
      style="margin-top: 110px; z-index: 1; position: absolute; height: 100%"
      hide-delimiters
      height="100%"
      :eager="true"
      show-arrows-on-hover
      cycle
    >
      <v-carousel-item
        v-for="image in images"
        :key="image.id"
        :alt="image.image_url"
        :eager="true"
        class="c-image"
      >
        <v-img
          height="100%"
          :eager="true"
          width="100%"
          style="object-fit: fill"
          :src="image.image_url"
          gradient="to bottom, rgba(0,0,0,.4), rgba(0,0,0,.4)"
        >
        </v-img>
      </v-carousel-item>
    </v-carousel>

    <v-container
      style="z-index: 999; position: absolute; max-height: 300px; pointer-events: none"
      class="mx-auto hc ma-auto"
      fill-height
    >
      <v-row
        align="center"
        class="white--text mx-auto mt-8"
        :class="[$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15']"
        justify="center"
      >
        <v-col class="white--text text-center pt-7 pt-md-0 mt-0 mt-15" cols="12" tag="h1">
          <span
            :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']"
            class="font-weight-light"
            style="font-family: Montserrat !important"
          >
            Bienvenidos a
          </span>

          <br />

          <span
            :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
            class="font-weight-bold"
            style="font-family: Montserrat !important"
          >
            DISCURSA
          </span>
          <div class="pb-5 pb-md-0"></div>

          <span :class="[$vuetify.breakpoint.smAndDown ? 'textd2' : 'textd1']">
            La primera firma especializada en México dedicada exclusivamente a la
            elaboración de contenidos discursivos.
          </span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import i1 from "@/assets/collage/1.jpg";
import i2 from "@/assets/collage/2.jpg";
import i3 from "@/assets/collage/3.jpg";
import i4 from "@/assets/collage/4.jpg";
import i5 from "@/assets/collage/5.jpg";
import i6 from "@/assets/collage/6.jpg";
import i7 from "@/assets/collage/7.jpg";
import i8 from "@/assets/collage/8.jpg";
import i9 from "@/assets/collage/9.jpg";
export default {
  name: "Landing",
  data() {
    return {
      images: [
        {
          id: i1,
          image_url: i1,
          text:
            "Somos la primera firma especializada en México dedicada exclusivamente a la elaboración de contenidos discursivos. ",
        },
        {
          id: "",
          image_url: i2,
          text:
            "Tenemos más de 15 años de experiencia escribiendo discursos para presidentes de la república, secretarios de estado, gobernadores, legisladores, candidatos y líderes empresariales.",
        },
        {
          id: "",
          image_url: i3,
          text:
            "Nuestros servicios están dirigidos principalmente a líderes y tomadores de decisiones de alto nivel, tanto del sector público como privado.",
        },
        {
          id: "",
          image_url: i4,
          text: "En DISCURSA creemos en el Poder de la Palabra.",
        },
        {
          id: "",
          image_url: i5,
          text: "Nuestra misión es hacer que tus ideas trasciendan.",
        },
        {
          id: "",
          image_url: i6,
          text: "Te ayudamos a conectar emocionalmente con tu audiencia.",
        },
        {
          id: "",
          image_url: i7,
          text:
            "Te brindamos el acompañamiento necesario para potenciar tus ideas hasta convertirlas en piezas discursivas memorables.",
        },
        {
          id: "",
          image_url: i8,
          text: "¿Quieres convencer y persuadir a tu audiencia? ",
        },
        {
          id: "",
          image_url: i9,
          text: "Nuestra misión en DISCURSA es confeccionar el discurso que necesitas.",
        },
      ],
      currentImage: 0,
    };
  },
  mounted() {},
  methods: {
    nextImage() {
      let length = this.images.length;
      if (this.currentImage < length - 1) {
        this.currentImage += 1;
      } else {
        this.currentImage = 0;
      }
    },
    prevImage() {
      let length = this.images.length;
      if (this.currentImage > 0) {
        this.currentImage -= 1;
      } else {
        this.currentImage = length - 1;
      }
    },
  },
};
</script>

<style>
.v-carousel .div {
  position: absolute;
  top: 0;
  width: 100%;
}

.hc {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gimage {
  background: center center no-repeat,
    -moz-linear-gradient(top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background: center center no-repeat,
    -webkit-gradient(linear, 0 0, 0 100%, from(rgba(0, 0, 0, 0.3)), to(rgba(0, 0, 0, 0.3)));
  background: center center no-repeat,
    -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background: center center no-repeat,
    -o-linear-gradient(top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background: center center no-repeat,
    linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
</style>
