<template>
  <v-app>
    <v-app-bar
      v-if="showNav"
      fixed
      hide-on-scroll
      prominent
      :color="color"
      height="110"
      style="z-index: 1000 !important"
    >
      <div class="d-flex flex-row align-self-center">
        <v-img
          @click="$router.push('/inicio').catch((err) => {})"
          class=""
          width="130"
          src="@/assets/logo_blue.png"
        ></v-img>
      </div>

      <v-toolbar-title
        class="d-none d-md-block font-weight-black white--text headline ml-n3"
      >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-none d-md-flex flex-row mt-8">
        <v-btn
          small
          @click="$vuetify.goTo('#discurso')"
          :to="{ name: 'Home' }"
          active-class="no-active"
          text
          class="font-weight-bold"
          style="opacity: 1 !important"
          link
          >Inicio</v-btn
        >
        <v-btn
          small
          @click="$vuetify.goTo('#about')"
          :to="{ name: 'Home' }"
          active-class="no-active"
          text
          class="font-weight-bold"
          style="opacity: 1 !important"
          link
          >Nosotros</v-btn
        >
        <v-btn
          small
          @click="$vuetify.goTo('#servicios')"
          :to="{ name: 'Home' }"
          active-class="no-active"
          text
          class="font-weight-bold"
          >Servicios</v-btn
        >
        <v-btn
          small
          @click="$vuetify.goTo('#metodo')"
          active-class="no-active"
          :to="{ name: 'Home' }"
          text
          class="font-weight-bold"
          >Método</v-btn
        >
        <v-btn
          small
          @click="$vuetify.goTo('#contacto')"
          active-class="no-active"
          :to="{ name: 'Home' }"
          text
          class="font-weight-bold"
          >Contacto</v-btn
        >

        <v-btn
          small
          @click="$vuetify.goTo('#members')"
          active-class="no-active"
          :to="{ name: 'Home' }"
          text
          class="font-weight-bold"
          >Equipo</v-btn
        >
        <v-btn
          small
          @click="$vuetify.goTo('#blog')"
          active-class="no-active"
          :to="{ name: 'Home' }"
          text
          class="font-weight-bold"
          >blog</v-btn
        >
      </div>
      <div class="d-block d-md-none mt-8">
        <v-dialog fullscreen v-model="dialog" transition="dialog-top-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
          </template>
          <v-card height="100%" :color="color">
            <v-btn
              @click="dialog = false"
              icon
              style="z-index: 999; position: absolute; top: 20px; right: 20px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-container fill-height>
              <v-row align="center">
                <v-btn
                  @click="
                    $vuetify.goTo('#discurso');
                    dialog = false;
                  "
                  x-large
                  text
                  class="font-weight-bold"
                  >Inicio</v-btn
                >
                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>

                <v-btn
                  @click="
                    $vuetify.goTo('#about');
                    dialog = false;
                  "
                  x-large
                  text
                  class="font-weight-bold"
                  >Nosotros</v-btn
                >
                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>
                <v-btn
                  @click="
                    $vuetify.goTo('#servicios');
                    dialog = false;
                  "
                  x-large
                  text
                  class="font-weight-bold"
                  >Servicios</v-btn
                >
                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>

                <v-btn
                  @click="
                    $vuetify.goTo('#metodo');
                    dialog = false;
                  "
                  x-large
                  text
                  class="font-weight-bold"
                  >Método</v-btn
                >
                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>

                <v-btn
                  @click="
                    $vuetify.goTo('#contacto');
                    dialog = false;
                  "
                  text
                  x-large
                  class="font-weight-bold"
                  >Contacto</v-btn
                >
                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>

                <v-btn
                  @click="
                    $vuetify.goTo('#members');
                    dialog = false;
                  "
                  text
                  x-large
                  class="font-weight-bold"
                  >Equipo</v-btn
                >

                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>

                <v-btn
                  @click="
                    $vuetify.goTo('#blog');
                    dialog = false;
                  "
                  text
                  x-large
                  class="font-weight-bold"
                  >Blog</v-btn
                >
                <v-divider style="max-width: 200px" class="mx-auto"></v-divider>

                <v-btn
                  @click="
                    $vuetify.goTo('#redes');
                    dialog = false;
                  "
                  text
                  x-large
                  class="font-weight-bold"
                  >Redes</v-btn
                >
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </v-app-bar>
    <v-main app class="">
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      color: "#f9f9f9",
    };
  },

  computed: {
    showNav() {
      if (this.$route.name !== "Login" && this.$route.name !== "Admin") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*,
*:before,
*:after {
  box-sizing: border-box;
}

#app {
  font-family: Montserrat, sans-serif, Arial, Helvetica;
}

.site-menu {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#metodo {
  background-color: #f2f2f2;
}

#target {
  background-image: linear-gradient(
    to right,
    #f2f2f2,
    #dee3f7,
    #bbd8fc,
    #86cffc,
    #1cc8f5
  );
}
#tarifas {
  background-image: linear-gradient(
    to right,
    #939393,
    #adadad,
    #c8c8c8,
    #e3e3e3,
    #ffffff
  );
}

.number {
  font-size: 2rem !important;
}

.texth1 {
  font-size: 5.5rem !important;
  font-weight: 700 !important;
  line-height: 5.5rem !important;
  letter-spacing: 0.1875rem !important;
  font-family: Montserrat "Work Sans", sans-serif !important;
}
.texth2 {
  font-size: 3.5rem !important;
  font-weight: 700;
  line-height: 3.8rem;
  letter-spacing: 0.1675rem !important;
  font-family: Montserrat "Work Sans", sans-serif !important;
}
.texth3 {
  font-size: 2.8rem !important;
  font-weight: 700;
  line-height: 3.8rem;
  letter-spacing: 0.1675rem !important;
  font-family: Montserrat "Work Sans", sans-serif !important;
}
.texth4 {
  font-size: 2.3rem !important;
  font-weight: 700;
  line-height: 3.8rem;
  letter-spacing: 0.1675rem !important;
  font-family: Montserrat "Work Sans", sans-serif !important;
}
.texth5 {
  font-size: 1.2rem !important;
  font-weight: 700;
  line-height: 3.8rem;
  letter-spacing: 0.1675rem !important;
  font-family: Montserrat "Work Sans", sans-serif !important;
}

.textd1 {
  font-size: 1.8rem !important;
}

.textd2 {
  font-size: 1.2rem !important;
}

.textd3 {
  font-size: 0.8rem !important;
}

.v-card {
  transition: opacity 1s ease-in-out;
  opacity: 0.8;
}
.step {
  transition: opacity 1s ease-in-out;
}

.v-card:not(.on-hover) {
  display: block !important;
  opacity: 1;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(28, 100, 171, 0.5),
    rgba(38, 110, 171, 0.6),
    rgba(38, 120, 171, 0.7)
  );
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.mh-90vh {
  max-height: 80vh !important;
}

.s-hover {
  cursor: pointer;
}
</style>
