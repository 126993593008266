<template>
  <v-container
    class="px-5 pt-8 px-md-n15 my-15"
    style="max-width: 900px; margin-top: 150px"
  >
    <div class="py-12"></div>
    <div class="mb-5" style="max-width: 40px">
      <v-divider class="py-1 primary" style="opacity: 1 !important"></v-divider>
    </div>
    <h2
      class="font-weight-bold mb-12"
      :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
    >
      La Importancia del Discurso
    </h2>
    <v-responsive class="mx-auto title font-weight-light my-8" max-width="920">
      <p style="font-size: 1.3rem" v-for="t in text" :key="t.key">
        {{ t }}
      </p>
    </v-responsive>
  </v-container>
</template>

<script>
export default {
  name: "Discurso",
  data() {
    return {
      text: [
        "Decía Octavio Paz que estamos hechos de palabras. Que ellas son nuestra única realidad o, al menos, el único testimonio de nuestra realidad. Y es cierto, la palabra es nuestra esencia, nuestro pensamiento. Somos lo que decimos. Por eso el discurso importa.",
        "Sabemos que el discurso es, esencialmente, un ejercicio personal. El problema es que, por lo regular, quienes ejercen el liderazgo y toman las decisiones, no cuentan ni con el tiempo ni con las herramientas para estructurar adecuadamente un mensaje y seleccionar la mejor forma de expresarlo.",
        "Nuestra misión en DISCURSA, es acompañarlos en esta tarea, para potenciar sus ideas hasta convertirlas en piezas discursivas efectivas y memorables. ",
      ],
    };
  },
};
</script>
