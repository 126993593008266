<template>
  <div>
    <v-container class="px-5 px-md-n15" style="max-width: 900px">
      <div class="mb-5" style="max-width: 40px">
        <v-divider class="py-1 black" style="opacity: 1 !important"></v-divider>
      </div>
      <span
        class="font-weight-bold mb-5"
        :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
      >
        Acerca de <br />
        Nosotros
      </span>

      <v-responsive
        class="mx-auto title font-weight-light my-8"
        max-width="920"
      >
        <p style="font-size: 1.3rem" v-for="u in us" :key="u.key">
          {{ u }}
        </p>
      </v-responsive>
    </v-container>

    <v-container class="text-center">
      <v-avatar class="mx-auto elevation-3 mb-12" size="108">
        <v-img height="75px" contain src="@/assets/mini_logo_blue.png"></v-img>
      </v-avatar>

      <div></div>

      <v-btn :to="{ name: 'Mensaje' }" color="grey" outlined large>
        <span class="grey--text text--darken-1 font-weight-bold">
          Mensaje del Equipo de Escritores
        </span>
      </v-btn>
    </v-container>

    <div class="py-12"></div>

  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      us: [
        "Discursa está integrada por un grupo de escritores profesionales con más de 15 años de experiencia escribiendo discursos para presidentes de la república, secretarios de estado, gobernadores, legisladores, candidatos y líderes empresariales.",
        "Con una sólida base de conocimientos en comunicación, políticas públicas y mensaje gubernamental, cada uno de nosotros ha desarrollado una amplia trayectoria de trabajo dedicada al diseño de contenidos y narrativas innovadoras a partir de los más altos estándares de calidad y excelencia.",
      ],
    };
  },
};
</script>
