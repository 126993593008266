<template>
  <div>
    <div class="py-12"></div>

    <v-container class="px-5">
      <div style="max-width: 70px">
        <v-divider class="grey" style="padding: 7px 0; opacity: 1 !important"></v-divider>
      </div>
      <h2
        class="font-weight-bold mb-5"
        :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
      >
        Blog DISCURSA
      </h2>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="pa-3 ma-1 ma-sm-0"
          v-for="({ title, image, description, slug, author }, i) in articles"
          :key="i"
        >
          <v-img :src="image" class="mb-4" height="205" max-width="100%"></v-img>

          <h3 class="font-weight-black mb-4 text-capitalize" v-text="title"></h3>
          <div class="my-2 text-capitalize font-weight-bold" style="font-size: 0.85rem">
            Por: <span class="" v-text="author"> </span>
          </div>
          <div class="font-weight-light mb-5" v-text="description"></div>

          <v-btn
            :to="{ name: 'Post', params: { name: `${slug}` } }"
            outlined
            small
            class="font-weight-black mr-3"
            text
            style="float: right !important"
          >
            Seguir Leyendo
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div class="py-12"></div>
  </div>
</template>

<script>
import { db } from "@/plugins/firebase.js";
export default {
  name: "Blog",
  data() {
    return {
      articles: [],
    };
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    async getArticles() {
      try {
        const blogPosts = await db.collection("blog").get();
        blogPosts.docs.forEach((post) => {
          this.articles.push(post.data());
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
