<template>
  <v-parallax
    :height="$vuetify.breakpoint.smAndDown ? '100%' : 850"
    src="@/assets/landing/landing3bw.jpg"
  >
    <v-container fill-height class="px-0 px-md-3" fluid>
      <v-row align="center" class="py-8">
        <v-col cols="12">
          <div class="mt-15 mt-md-0">
            <div class="mt-n15" style="max-width: 50px;">
              <v-divider class="py-1 grey" style=" opacity: .9"></v-divider>
            </div>

            <span
              class="font-weight-bold"
              :class="[$vuetify.breakpoint.smAndDown ? 'texth3' : 'texth2']"
            >
              Lo que <br />Hacemos
            </span>
          </div>
          <v-row class="mt-3 mt-sm-15">
            <v-col
              class="my-6"
              cols="6"
              sm="6"
              md="4"
              v-for="s in services"
              :key="s.key"
            >
              <div style="max-width: 160px">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="s-hover" v-bind="attrs" v-on="on">
                      <div class="mr-auto" style="max-width: 40px">
                        <v-divider
                          class="py-1 primary lighten-1"
                          style="opacity: 1;"
                        ></v-divider>
                      </div>
                      <div
                        v-html="s.name"
                        style="letter-spacing: .2em;"
                        class="text-uppercase"
                      ></div>
                    </div>
                  </template>
                  <div style="max-width: 220px">
                    <span> {{ s.tip }} </span>
                  </div>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>

          <div
            class="mt-8 mt-sm-15 mx-auto text-center"
            style="font-size: 2.5rem;"
          >
            En <strong>DISCURSA</strong> tenemos la solución
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
  export default {
    name: "Servicios",
    data() {
      return {
        services: [
          {
            name: "Discursos",
            tip:
              "Si necesitas un discurso formal, líneas discursivas o talking points…",
          },
          {
            name: "Guiones",
            tip:
              "Si requieres guiones para colaboraciones en radio y televisión, videocolumnas, podcasts o TED Talks…",
          },
          {
            name: "Contenidos",
            tip:
              "Si buscas contenidos para artículos de opinión, ensayos, prólogos o para un libro …",
          },
          {
            name: "Estrategias Argumentativas",
            tip:
              "Si necesitas ideas fuerza para un posicionamiento público, una conferencia de prensa, un informe o para una entrevista o debate …",
          },
          {
            name: "CIRP <br/> Estrategias de mensaje",
            tip:
              "Si necesitas construir mensajes clave en función de la coyuntura política ...",
          },
          {
            name: "Narrativas",
            tip:
              "Si necesitas una historia fresca y renovada, de tu empresa o proyecto … ",
          },
        ],
      };
    },
  };
</script>
